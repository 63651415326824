import React from "react";
import I18nContext from "../lib/i18n-context";

const OurWorkQuote = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourWorkQuote;

  return (
    <section className="h-auto w-full text-darkblue">
      <div className="container font-arima max-w-screen-xl flex flex-col justify-center text-left px-10 py-16 space-y-8 lg:px-20">
        <figure className="text-center max-w-4xl mx-auto">
          <blockquote className="font-poppins">
            <p className="text-xl md:text-2xl lg:text-3xl xl:text-5xl italic">
              {data.quote}
            </p>
          </blockquote>
          <figcaption className="pt-4 md:pt-8 text-sm md:text-lg lg:text-xl">
            {data.company}
          </figcaption>
        </figure>
      </div>
    </section>
  );
};

export default OurWorkQuote;
