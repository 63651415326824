import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import NavBar from "../components/nav";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import "aos/dist/aos.css";
import AOS from "aos";
import OurWorkBanner from "../components/OurWorkBanner";
import OurWorkQuote from "../components/OurWorkQuote";
import OurReachWhatWeDo from "../components/OurReachWhatWeDo";
import AllOurWork from '../components/AllOurWork'

const OurWork = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Layout>
      <Seo
        title={"Our work"}
        description={
          "We don’t just show clients where and how they can create impact in this dynamic market; we help them realise it."
        }
      />
      <header>
        <NavBar
          logo={"/assets/main-logo.svg"}
          navClass={"blue-navbar"}
          svgColour="#fff"
        />
        <OurWorkBanner />
      </header>
      <main id="main">
        <OurWorkQuote />
        <AllOurWork />
        <OurReachWhatWeDo />
        <Footer />
      </main>
    </Layout>
  );
};

export default OurWork;

export const pageQuery = graphql`
  query OurWorkQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
