import React from "react";
import I18nContext from "../lib/i18n-context";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const OurWorkBanner = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourWorkBanner;

  const breakpoints = useBreakpoint();

  return (
    <section className="relative text-center text-white font-arima flex flex-col items-center justify-end h-screen overflow-hidden">
      <div className="max-w-3xl pb-10 md:pb-16 lg:pb-40 px-6 md:px-10">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-light pb-6 md:pb-10 lg:pb-4">
          {data.title}
        </h1>
        <blockquote className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-light p-8 italic drop-shadow-2xl">
          <p style={{ textShadow: "0.5px 0.5px #000" }}>{data.description}</p>
        </blockquote>
      </div>
      {/* VIDEO */}
      {breakpoints.md ? (
        <img
          src="/assets/about-us-design.jpg"
          alt="QIVA main header"
          style={{ zIndex: "-1" }}
          className="absolute w-auto min-w-full min-h-full max-w-none"
        />
      ) : (
        <video
          preload={"auto"}
          playsInline
          muted
          autoPlay
          poster="/assets/video-poster.png"
          loop
          style={{ zIndex: "-1" }}
          className="absolute w-auto min-w-full min-h-full max-w-none"
        >
          <source src="/assets/header-video-min.mp4" type="video/mp4" />
          <source src="/assets/header-video.webm" type="video/webm" />
        </video>
      )}
    </section>
  );
};

export default OurWorkBanner;
