import React from "react";
import { Link } from "gatsby";

const WhatWeDo = (props) => (
  <div className="container max-w-screen-xl px-10 space-y-6 sm:pb-10 lg:pt-10 text-darkblue">
    <div>
      <h3 className="font-arima text-2xl lg:text-3xl"> {props.title} </h3>
    </div>
    <div className="grid place-content-center place-items-center sm:grid-cols-3 border-t-2 py-8 space-y-8 sm:space-y-0 gap-4 md:gap-6 lg:gap-10 xl:gap-16">
      <div className="text-center space-y-4 px-4 sm:px-0">
        <img
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-duration="1700"
          className="w-full"
          src={props.firstImg}
          alt={props.firstAlt}
        />
        <h3 className="font-arima text-2xl lg:text-3xl pt-4">
          {" "}
          {props.firstTitle}{" "}
        </h3>
        <div>
          <Link to={props.firstPath}>
            {" "}
            <button className="text-xs border-darkblue border rounded-xl px-10 py-1 lg:px-14 lg:text-sm hover:bg-gray-500 hover:text-white">
              {" "}
              {props.buttonTitle}{" "}
            </button>{" "}
          </Link>
        </div>
      </div>
      <div className="text-center space-y-4 px-4 sm:px-0">
        <img
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-duration="1700"
          className="w-full"
          src={props.secondImg}
          alt={props.secondAlt}
        />
        <h3 className="font-arima text-2xl lg:text-3xl pt-4">
          {" "}
          {props.secondTitle}{" "}
        </h3>
        <div>
          <Link to={props.secondPath}>
            <button className="text-xs border-darkblue border rounded-xl px-10 py-1 lg:px-14 lg:text-sm hover:bg-gray-500 hover:text-white">
              {" "}
              {props.buttonTitle}{" "}
            </button>
          </Link>
        </div>
      </div>
      <div className="text-center space-y-4 px-4 sm:px-0">
        <img
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-duration="1700"
          className="w-full"
          src={props.thirdImg}
          alt={props.thirdAlt}
        />
        <h3 className="font-arima text-2xl lg:text-3xl pt-4">
          {" "}
          {props.thirdTitle}{" "}
        </h3>
        <div>
          <Link to={props.thirdPath}>
            <button className="text-xs border-darkblue border rounded-xl px-10 py-1 lg:px-14 lg:text-sm hover:bg-gray-500 hover:text-white">
              {" "}
              {props.buttonTitle}{" "}
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default WhatWeDo;
