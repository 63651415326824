import React from "react";
import I18nContext from "../lib/i18n-context";
import WhatWeDo from "../components/WhatWeDo";

const OurReachWhatWeDo = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.whatWeDo;

  return (
    <WhatWeDo
      title={data.title}
      firstTitle={data.services}
      firstImg={"/assets/our-model-display.png"}
      firstAlt={"display design of Our services"}
      firstPath={"/our-services"}
      secondTitle={data.ideas}
      secondImg={"/assets/ideas-wed.png"}
      secondAlt={"display design of Our work"}
      secondPath={"/ideas"}
      thirdTitle={data.work}
      thirdImg={"/assets/why-us-display.png"}
      thirdAlt={"display design of Our work"}
      thirdPath={"/our-work"}
      buttonTitle={data.buttonTitle}
    />
  );
};

export default OurReachWhatWeDo;
