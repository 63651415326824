import React from "react";
import I18nContext from "../lib/i18n-context";
import WorkCardsBlock from "../components/WorkCardsBlock";

const AllOurWork = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.allOurWork;
  const dataOurWork = locale.ourWork;

  return (
    <section className="container max-w-screen-xl h-auto w-full text-darkblue space-y-6 pt-8 px-10">
      <div>
        <h3 className="font-arima text-2xl lg:text-3xl">{data.title}</h3>
      </div>
      <div className="flex flex-col justify-center text-left pt-8 pb-16 space-y-8 border-t-2">
        <WorkCardsBlock data={dataOurWork} border={true} />
      </div>
    </section>
  );
};

export default AllOurWork;
